import styled from "styled-components";

const MetaText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  font-family: ${props => props.theme.headerFontFamily};
  color: #717171;
`;

export default MetaText;
