import React from "react";
import { Link } from "gatsby";

function CategoriesList(props) {
  const { categories } = props;

  const cs = categories.map((c, index, arr) => {
    const link = <Link key={c.slug} to={`/category/${c.slug}/`}>#{c.name}</Link>;
    return index + 1 < arr.length ? [link, ", "] : [link];
  });
  return [].concat(...cs);
}

export default CategoriesList;
