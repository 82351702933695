import { format as formatDateFns } from "date-fns";

function getUTCDate(date) {
  const d = new Date(date);
   return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
  );
};

export function formatDate(dateString, format = "DD MMM YYYY") {
  return formatDateFns(getUTCDate(dateString), format);
}
